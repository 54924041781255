import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { MatTableDataSource } from "@angular/material/table";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject, Subscription } from "rxjs";
import { UserSettings } from "src/app/models/user";
import { User } from "src/app/models/User-Access-Management/user";
import { AddAdminContact, AddAdminContactResult, GetUsers } from "src/app/store/uam/uam.actions";
import { UamState } from "src/app/store/uam/uam.reducer";
import { InfoShow } from "src/app/store/ui-state.actions";
import { UserState } from "src/app/store/user.reducer";

@Component({
    selector: 'app-uam-admin',
    templateUrl: './uam-initial-popup.component.html',
    styleUrls: ['./uam-initial-popup.component.scss'],
  })
  export class UAMInitialPopupComponent implements OnInit {
    @ViewChild('select') matselection: MatSelect|undefined;
    
    dataSource = new MatTableDataSource<any>();
    public subscriptions: Subscription[] = [];
    userSettings: UserSettings;
    isMHIEmployee: boolean;
    Users: User[] = [];
    formField1: dropdownData;
    formField2: dropdownData;
    formField3:dropdownData;
    selectedItem: any;
    displayedColumns: string[] = ['select', 'email', 'firstName'];
    userAdminList:AdminContact[];
    HIDAdminList: AdminContact[];
    RetailAdminList: AdminContact[];
    adminContactList: AdminContactRequest;
    isAdminConfirm : boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<any>, public store: Store<{ userState: UserState,uamState: UamState, }>, private actions$: Actions)
    {
        this.store.dispatch(GetUsers());
        
        this.subscriptions.push(this.store.select(state => state.userState.settings).subscribe(userSettings => {
            if (userSettings != null) {
              this.userSettings = userSettings;
              this.isMHIEmployee = userSettings.isMHIUser;
            }
          }));
    } 

    ngOnInit(){
        this.store.select(state => state?.uamState?.users).subscribe(data => {
            if (data) {
                var isMHIUserr = this.userSettings.isMHIUser ? 1 : 0;

                if (!this.userSettings.isMHIUser) {
                    this.Users = data.filter(x => x.isMhiuser === isMHIUserr);
                } else {
                    this.Users = data;
                }

                this.dataSource.data = this.Users;
                this.updateDropdownDataSources();
            }
        });

        this.actions$.pipe(ofType(AddAdminContactResult)).subscribe((data) => {
          if(data){
            this.store.dispatch(InfoShow({ message: 'Admin contacts have been successfully saved.' }));
          }
        });

      
    }
 
    adminSelection(event){
      this.isAdminConfirm = event.target.checked;
    }

    updateDropdownDataSources() {
      this.formField1 ={
        dataSource: JSON.parse(JSON.stringify(this.dataSource.data)),
        formFieldName: 'User Access Management Contact',
        formSelectedValue: '',
        displayedColumns: this.displayedColumns,
      };
      this.formField2 = {
        dataSource: JSON.parse(JSON.stringify(this.dataSource.data)),
        formFieldName: 'Hospital Pharmacy Contact',
        formSelectedValue: '',
        displayedColumns: this.displayedColumns,

      };
      this.formField3 = {
        dataSource: JSON.parse(JSON.stringify(this.dataSource.data)),
        formFieldName: 'Retail Pharmacy/Contract Pharmacy Contact',
        formSelectedValue: '',
        displayedColumns: this.displayedColumns,
      };
    }

    formField1_selected(event: any[]) {
      const formfielddata = this.formField1;
      formfielddata.selectedData = event;
            this.formField1 = this.deepcopy(formfielddata);
              this.userAdminList = [];
              event.forEach(x => {
                this.userAdminList.push({
                  adminTypeId : 1,
                  uid : x.userId
            })
        });
    }
      

    formField2_selected(event: any[]) {
      const formfielddata = this.formField2;
      formfielddata.selectedData = event;
            this.formField2 = this.deepcopy(formfielddata);
              this.HIDAdminList = [];
              event.forEach(x => {
                 this.HIDAdminList.push({
                  adminTypeId : 2,
                  uid : x.userId
            })
        });
    }

    formField3_selected(event: any[]) {
      const formfielddata = this.formField3;
      formfielddata.selectedData = event;
            this.formField3 = this.deepcopy(formfielddata);
              this.RetailAdminList = [];
              event.forEach(x => {
                 this.RetailAdminList.push({
                  adminTypeId : 3,
                  uid : x.userId
          })
      }); 
    }

    onCloseClick(){
      this.dialog.close();
    }

    onSaveChanges(){
      let adminList : AdminContact[] = [];
      adminList.push(...this.userAdminList);
      adminList.push(...this.HIDAdminList);
      adminList.push(...this.RetailAdminList);
      this.adminContactList = {
        adminContacts :adminList
      }
      if(adminList.length > 0){
        this.store.dispatch(AddAdminContact({adminContact : this.adminContactList}))
      }
      this.dialog.close();

    }

    deepcopy(data: any){
      if(data){
      return JSON.parse(JSON.stringify(data));
      }
      return null;
    }
      
  }


  export interface dropdownData {
    dataSource: any;
    formFieldName: string;
    formSelectedValue: string;
    selectedData?: any[];
    displayedColumns: string[];
    isAdmin?: any;
  }

  export interface AdminContact{
    uid: number,
    adminTypeId: number
  }

  export interface AdminContactRequest{
    adminContacts : AdminContact[];
  }

  export interface AdminContactDetails{
    uid: number,
    adminTypeId: number,
    firstName: string,
    lastName:string,
    isMhiUser: number,
    corpId: number,
    csgId: number,
    email: string
  }